// eslint-disable-next-line no-undef
interface FetchOptions extends RequestInit {
  headers?: Record<string, string>;
}

const jsonContentTypes = (contentType: string) =>
  ['application/json', 'multipart/form-data'].includes(contentType);

enum RESPONSE_TYPE {
  TEXT = 'text',
  JSON = 'json',
}

const fetchService = async <T>(
  url: string,
  options: FetchOptions = {}
): Promise<{
  data: T;
  status: number;
}> => {
  const response = await fetch(url, options);
  const contentType = response.headers.get('content-type');
  const responseType =
    contentType && contentType.split(';').some(jsonContentTypes)
      ? RESPONSE_TYPE.JSON
      : RESPONSE_TYPE.TEXT;

  if (!response.ok) {
    const errorData = await response[responseType]();

    throw new Error(errorData.message || 'Network response was not ok');
  }

  const data = await response[responseType]();

  return {
    status: response.status,
    data,
  };
};

const get = <T>(url: string, options: FetchOptions = {}) =>
  fetchService<T>(url, { ...options, method: 'GET' });
const post = <T>(url: string, body: unknown, options: FetchOptions = {}) =>
  fetchService<T>(url, { ...options, method: 'POST', body: JSON.stringify(body) });
const postFormData = <T>(url: string, body: FormData, options: FetchOptions = {}) =>
  fetchService<T>(url, { ...options, method: 'POST', body });
const put = <T>(url: string, body: unknown, options: FetchOptions = {}) =>
  fetchService<T>(url, { ...options, method: 'PUT', body: JSON.stringify(body) });
const del = <T>(url: string, options: FetchOptions = {}) =>
  fetchService<T>(url, { ...options, method: 'DELETE' });

export default {
  get,
  post,
  put,
  postFormData,
  delete: del,
};
