/* eslint-disable consistent-return */
import * as i18Config from '../../../next-i18next.config';
import {
  RECENTLY_VIEWED_PRODUCTS,
  RVP_LIST_LENGTH_LIMIT,
  SIZE_TRANSFER_LENGTH,
  SIZE_TRANSFER_SIZE,
} from '../constants/localStorageKeys';
import type {
  EntryPoint,
  Navigation,
  NavigationItem,
  Product,
  Subscription,
} from '../graphql/codegen';
import logger from '../logger';
import { buildApiUrl } from '../parsers';
import fetchService from './fetchService';
import { CustomEventTypes } from '../constants';
import type { SearchResultProductColor } from '../../components/shared/header/searchBoxV2/types';

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage
export const storageAvailable = (type: 'localStorage' | 'sessionStorage') => {
  let storage: Storage;

  try {
    storage = window[type];

    const x = '__storage_test__';

    storage.setItem(x, x);
    storage.removeItem(x);

    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      window[type] &&
      window[type].length !== 0
    );
  }
};

type RVPImage = {
  altText?: string;
  base64BackgroundImage?: string;
  imageType?: string;
  url?: string;
};

export type RecentlyViewedProduct = Product & {
  sku?: string;
  primaryImage?: RVPImage;
  hoverImage?: RVPImage;
  formattedBasePrice?: string;
  formattedFromPrice?: string;
  color?: string | SearchResultProductColor;
  stockQuantity?: number;
};

export const getRecentlyViewedProductsFromLocalStorage = (
  country: string
): RecentlyViewedProduct[] => {
  try {
    if (storageAvailable('localStorage')) {
      const rvpKey = `${country.toLowerCase()}_${RECENTLY_VIEWED_PRODUCTS}`;
      const rvp = JSON.parse(localStorage.getItem(rvpKey) || '[]');

      if (Array.isArray(rvp)) {
        const result: RecentlyViewedProduct[] = rvp
          .map(p => (!p.code && !p.baseProduct ? { ...p, code: p.sku } : p))
          .map(p => (!p.sku ? { ...p, sku: p.code || p.baseProduct } : p))
          .filter(p => p.sku);

        return result;
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }

  return [];
};

export const addProductToRecentlyViewedLocalStorage = (country: string, product: Product) => {
  const isLocalStorageAvailable = storageAvailable('localStorage');

  if (!isLocalStorageAvailable) {
    return;
  }

  const localStorageProducts = getRecentlyViewedProductsFromLocalStorage(country);
  const currentIndex = localStorageProducts.findIndex(
    (p: RecentlyViewedProduct) =>
      p.sku?.toLowerCase() === product.code?.toLowerCase() ||
      p.sku?.toLowerCase() === product.baseProduct?.toLowerCase()
  );

  if (currentIndex >= 0) {
    localStorageProducts.splice(currentIndex, 1);
  }

  localStorageProducts.unshift({
    ...(product as RecentlyViewedProduct),
    sku: product.baseProduct || product.code,
  });

  if (isLocalStorageAvailable && localStorageProducts.length) {
    if (localStorageProducts.length > RVP_LIST_LENGTH_LIMIT) {
      localStorageProducts.splice(
        RVP_LIST_LENGTH_LIMIT,
        localStorageProducts.length - RVP_LIST_LENGTH_LIMIT
      );
    }

    localStorage.setItem(
      `${country}_${RECENTLY_VIEWED_PRODUCTS}`,
      JSON.stringify(localStorageProducts)
    );

    const customEvent = new CustomEvent(CustomEventTypes.RECENTLY_VIEWED_PRODUCTS_LIST_UPDATED);

    window.dispatchEvent(customEvent);
  }
};

export const getLocation = () =>
  window.location.pathname + window.location.hash.replace('#', '?__');

export const checkEnvTiersAndLocales = (locale: string) => {
  let toFetch = false;
  const envLocales = process.env.LOCALE?.trim()
    .toLowerCase()
    ?.split(',')
    .filter(l => !!l);
  const envTiers = process.env.TIER?.trim()
    .toLowerCase()
    ?.split(',')
    .filter(t => !!t);

  if (!envLocales?.length && !envTiers?.length) {
    toFetch = true;
  } else {
    toFetch = !!envLocales?.includes(locale);

    const tiers = i18Config.tiers as { [k: string]: string[] };

    envTiers?.forEach(tier => {
      if (tiers[tier].includes(locale)) {
        toFetch = true;
      }
    });
  }

  return toFetch;
};

export const getLocalesToBeBuiltWithEnvTiersLocales = (locales: string[]) => {
  let localesToBeBuilt: string[] = [];

  const envLocales = process.env.LOCALE?.trim()
    .toLowerCase()
    ?.split(',')
    .filter(l => !!l);

  if (envLocales && envLocales.length) {
    localesToBeBuilt = envLocales.filter(locale => {
      if (locales.includes(locale)) {
        return true;
      }

      logger.warn(`Locale ${locale} does not exist.`);

      return false;
    });
  }

  const envTiers = process.env.TIER?.trim()
    .toLowerCase()
    ?.split(',')
    .filter(t => !!t);
  const tiers = i18Config.tiers as { [k: string]: string[] };

  if (envTiers && envTiers.length) {
    envTiers.forEach(tier => {
      if (tiers[tier]) {
        localesToBeBuilt = [
          ...localesToBeBuilt,
          ...tiers[tier].filter(tierLocale => locales.includes(tierLocale)),
        ];
      } else {
        logger.warn(`Tier ${tier} does not exist.`);
      }
    });
  }

  localesToBeBuilt = localesToBeBuilt.filter(
    (locale, index) => localesToBeBuilt.indexOf(locale) === index
  );

  if (!localesToBeBuilt.length) {
    localesToBeBuilt = locales;
  }

  return localesToBeBuilt;
};

type ActiveNodeType =
  | { navItem: NavigationItem | null | undefined; indexArray: string[] }
  | undefined;

const getActiveNode = (n: NavigationItem | undefined | null, plpPath: string): ActiveNodeType => {
  if (!n?.children && n?.url && n.url === plpPath) {
    return { navItem: n, indexArray: [] };
  }

  for (let i = 0; i < (n?.children ?? []).length; i++) {
    const node = getActiveNode((n?.children ?? [])[i], plpPath);

    if (node) {
      node.indexArray.unshift(`${i}`);

      return node;
    }
  }
};

export const getActiveNavItems = (nav: Navigation, plpPath: string): ActiveNodeType => {
  for (let i = 0; i < (nav.navigation ?? []).length; i++) {
    const navItem = (nav.navigation ?? [])[i];
    const node = getActiveNode(navItem, plpPath);

    if (node) {
      node.indexArray.unshift(`${i}`);

      return node;
    }
  }
};

export const getStyleObject = (styleModifier: string) => {
  const styleRegex = /style="([^"]*)"/;
  const matches = styleRegex.exec(styleModifier);
  const cssStyleString = matches && matches.length > 0 ? matches[1] : undefined;

  if (!cssStyleString) {
    return undefined;
  }

  const styleObject: {
    [key: string]: string;
  } = {};

  cssStyleString.split(';').forEach(pair => {
    const [key, value] = pair.split(':');

    if (key && value) {
      const camelCaseKey = key.trim().replace(/-(\w)/g, (_, c) => c.toUpperCase());

      styleObject[camelCaseKey] = value.trim();
    }
  });

  return styleObject;
};

export const getClassNames = (styleModifier: string) => {
  const classNameRegex = /class="([^"]*)"/;
  const matches = classNameRegex.exec(styleModifier);
  const classNames = matches && matches.length > 0 ? matches[1] : undefined;

  return classNames;
};

/**
 * Parse formatted price as not everywhere we have the unformatted price available
 *
 * @param currencyLiteral formatted price with currency and with double decimal or no decimal
 * @returns {number} number as float if 2 decimals and as integer if no decimals
 */
export const parseFormattedPrice = (currencyLiteral: string) => {
  const sanitizedNumber = currencyLiteral.replaceAll(/[^\d.,]/g, '');

  const normalizedSeparators = sanitizedNumber.replaceAll(',', '.');

  const numberParts = normalizedSeparators.split('.');

  const [possibleDecimal, ...restNumber] = [...numberParts].reverse();

  if (possibleDecimal.length === 2 && restNumber.length > 0) {
    return parseFloat([restNumber.reverse().join(''), possibleDecimal].join('.'));
  }

  return parseFloat(numberParts.join(''));
};

export const sortPriceRangeCallback = (prevItem: { name: string }, nextItem: { name: string }) => {
  const prevFirstNumberMatch = prevItem.name.replace(/[.,]/g, '').match(/\d+/)?.[0];
  const nextFirstNumberMatch = nextItem.name.replace(/[.,]/g, '').match(/\d+/)?.[0];

  if (!(prevFirstNumberMatch && nextFirstNumberMatch)) {
    return 0;
  }

  try {
    const prevNumber = parseInt(prevFirstNumberMatch, 10);
    const nextNumber = parseInt(nextFirstNumberMatch, 10);

    if (prevNumber < nextNumber) {
      return -1;
    }

    if (prevNumber > nextNumber) {
      return 1;
    }
  } catch {
    return 0;
  }

  return 0;
};

export const syncStorageSizeFilter = (size: string[] | undefined, length: string[] | undefined) => {
  if (storageAvailable('localStorage')) {
    if (size) {
      localStorage.setItem(SIZE_TRANSFER_SIZE, JSON.stringify(size));
    } else {
      localStorage.removeItem(SIZE_TRANSFER_SIZE);
    }

    if (length) {
      localStorage.setItem(SIZE_TRANSFER_LENGTH, JSON.stringify(length));
    } else {
      localStorage.removeItem(SIZE_TRANSFER_LENGTH);
    }
  }
};

export const formatNumberByMask = (number: number, mask: string): string => {
  const invalidDecimalMaskErrorMessage = `Invalid mask: "${mask}". Cannot have multiple decimal separators.`;
  const invalidThousandsMaskErrorMessage = `Invalid mask: "${mask}". Cannot have multiple thousands group separators.`;
  const numberErrorMessage = `The provided value "${number}" is not a number.`;

  if (typeof number !== 'number' || Number.isNaN(number)) {
    throw new Error(numberErrorMessage);
  }

  let decimalSeparator = '';

  if (mask.includes('.0')) {
    if (decimalSeparator !== '') {
      throw new Error(invalidDecimalMaskErrorMessage);
    }

    decimalSeparator = '.';
  }

  if (mask.includes(',0')) {
    if (decimalSeparator !== '') {
      throw new Error(invalidDecimalMaskErrorMessage);
    }

    decimalSeparator = ',';
  }

  if (mask.includes("'0")) {
    if (decimalSeparator !== '') {
      throw new Error(invalidDecimalMaskErrorMessage);
    }

    decimalSeparator = "'";
  }

  const [integerPart, decimalPart] =
    decimalSeparator !== '' ? mask.split(decimalSeparator) : [mask, ''];
  const decimalPlaces = decimalPart ? decimalPart.length : 0;

  let thousandSeparator = '';

  if (integerPart.includes(',')) {
    if (thousandSeparator !== '') {
      throw new Error(invalidThousandsMaskErrorMessage);
    }

    thousandSeparator = ',';
  }

  if (integerPart.includes(' ')) {
    if (thousandSeparator !== '') {
      throw new Error(invalidThousandsMaskErrorMessage);
    }

    thousandSeparator = ' ';
  }

  if (integerPart.includes(' ')) {
    if (thousandSeparator !== '') {
      throw new Error(invalidThousandsMaskErrorMessage);
    }

    thousandSeparator = ' ';
  }

  if (integerPart.includes('.')) {
    if (thousandSeparator !== '') {
      throw new Error(invalidThousandsMaskErrorMessage);
    }

    thousandSeparator = '.';
  }

  if (integerPart.includes("'")) {
    if (thousandSeparator !== '') {
      throw new Error(invalidThousandsMaskErrorMessage);
    }

    thousandSeparator = "'";
  }

  if (integerPart.includes('’')) {
    if (thousandSeparator !== '') {
      throw new Error(invalidThousandsMaskErrorMessage);
    }

    thousandSeparator = '’';
  }

  const groupSize = Math.max(...integerPart.split(thousandSeparator).map(part => part.length));

  let formattedNumber = number.toFixed(decimalPlaces);
  // eslint-disable-next-line prefer-const
  let [integerDigits = '', decimalDigits = ''] = formattedNumber.split('.');

  if (thousandSeparator) {
    let groupedInteger = '';
    let integerLength = integerDigits.length;

    while (integerLength > 0) {
      if (groupedInteger) {
        groupedInteger = thousandSeparator + groupedInteger;
      }

      const substringStart = Math.max(0, integerLength - groupSize);

      groupedInteger = integerDigits.substring(substringStart, integerLength) + groupedInteger;
      integerLength -= groupSize;
    }

    integerDigits = groupedInteger;
  }

  if (decimalPlaces > 0) {
    formattedNumber = `${integerDigits}${decimalSeparator}${decimalDigits}`;
  } else {
    formattedNumber = integerDigits;
  }

  return formattedNumber;
};

type SubscribeRequest = {
  isNewsLetterSubscription: boolean;
  email: string;
  entryPoint: EntryPoint;
  productCode?: string;
  gridValue1?: string;
  gridValue2?: string;
  password?: string;
  name?: string;
  lastName?: string;
  preferredCategory?: string;
};

export const subscribeToGstar = async (params: SubscribeRequest): Promise<Subscription> => {
  const {
    isNewsLetterSubscription,
    entryPoint,
    productCode,
    gridValue1,
    gridValue2,
    preferredCategory,
    email,
    password,
    name,
    lastName,
  } = params;
  const query = new URLSearchParams();

  query.set('isNewsLetterSubscription', isNewsLetterSubscription ? 'true' : 'false');
  query.set('entryPoint', entryPoint);

  if (productCode) {
    query.set('productCode', productCode);
  }

  if (gridValue1) {
    query.set('gridValue1', gridValue1);
  }

  if (gridValue2) {
    query.set('gridValue2', gridValue2);
  }

  if (preferredCategory) {
    query.set('preferredCategory', preferredCategory);
  }

  const formData = new FormData();

  formData.append('email', email);
  formData.append('password', password || '');
  formData.append('name', name || '');
  formData.append('lastName', lastName || '');

  const url = buildApiUrl(`api/v3/user/subscribe?${query.toString()}`);
  const response = (await fetchService.postFormData<Subscription>(url, formData)).data;

  return response;
};

export const escapePercentageDiscountValue = (value: string): string => {
  const escapedValue = value.replace(/^-/, '\\-');

  return escapedValue;
};

export const checkForFacetKeysInPath = (facetKeys: string[], path: string): boolean =>
  facetKeys?.some(item => {
    const re = new RegExp(`[&?]${item}=`);

    return re.test(path);
  });
