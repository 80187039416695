import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const BellIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1024 1024"
    width="100%"
    height="100%"
    xmlSpace="preserve"
    $hoverColor={hoverColor}
  >
    <g fill={color}>
      <path d="M10.664 419.543c0-78.027 17.406-149.617 52.215-214.77 34.809-65.152 81.547-119.187 140.21-162.101l55.15 74.906c-46.934 34.332-84.286 77.637-112.055 129.914-27.77 52.281-41.653 109.63-41.653 172.051zm844.797 0c0-62.422-13.879-119.77-41.649-172.05-27.769-52.278-65.12-95.583-112.05-129.915l55.14-74.906c58.668 42.914 105.407 96.95 140.22 162.101 34.8 65.153 52.21 136.743 52.21 214.77zM104.53 840.891v-93.633h93.867V419.543c0-64.762 19.555-122.309 58.665-172.637 39.113-50.324 89.957-83.293 152.535-98.898v-32.77c0-19.508 6.843-36.09 20.53-49.742 13.692-13.656 30.313-20.484 49.868-20.484 19.555 0 36.18 6.828 49.867 20.484 13.692 13.652 20.535 30.234 20.535 49.742v32.77c62.579 15.605 113.422 48.574 152.532 98.898 39.113 50.328 58.672 107.875 58.672 172.637v327.715h93.859v93.633zm375.465 140.445c-25.812 0-47.91-9.164-66.293-27.5-18.383-18.336-27.574-40.383-27.574-66.129h187.734c0 25.746-9.191 47.793-27.574 66.129-18.379 18.336-40.476 27.5-66.293 27.5zm-187.73-234.078H667.73V419.543c0-51.496-18.378-95.582-55.144-132.258-36.766-36.672-80.961-55.008-132.59-55.008-51.625 0-95.82 18.336-132.586 55.008-36.765 36.676-55.144 80.762-55.144 132.258zm0 0" />
    </g>
  </Svg>
);

export default BellIcon;
