import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const HeartEmptyIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg viewBox="0 0 1024 1024" $hoverColor={hoverColor}>
    <g fill={color}>
      <path d="M88 168c97-97 251-100 352-11l10 11 61 60 60-60 11-11a256 256 0 0 1 370 352l-10 11-10 11-421 421L88 530l-10-11-9-11a256 256 0 0 1 19-340Zm61 60c-59 58-66 150-23 214l7 10 7 8 9 9 362 362 359-359 9-9a171 171 0 0 0-228-252l-10 8-10 9-120 121-121-121c-67-67-175-67-241 0Zm0 0" />
    </g>
  </Svg>
);

export default HeartEmptyIcon;
