import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

const HeartFullIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg viewBox="0 0 1024 1024" $hoverColor={hoverColor}>
    <g fill={color}>
      <path d="M88 168a256 256 0 0 0-19 340l9 11 10 11 423 422 421-421 10-11a256 256 0 0 0-360-363l-11 11-60 60-61-60a256 256 0 0 0-362 0Zm0 0" />
    </g>
  </Svg>
);

export default HeartFullIcon;
