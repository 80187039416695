import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';

import type { Slot } from '../../../amplienceTypes/content';

import { listAllChildren } from '../../dom';

type LayoutDesignContextType = {
  hasStickyProductImageGallery?: boolean;
  hasProductAccordions?: boolean;
};

const LayoutDesignContext = createContext<LayoutDesignContextType>({
  hasStickyProductImageGallery: undefined,
  hasProductAccordions: undefined,
});

export const useLayoutDesignContext = () => useContext(LayoutDesignContext);

export const LayoutDesignContextProvider = ({
  children,
  layoutComponents = [],
  ...props
}: {
  children: ReactNode;
  layoutComponents?: Slot[];
} & LayoutDesignContextType) => {
  const value = useMemo(() => {
    const layoutComponentsChildren = layoutComponents.reduce<Slot[]>(listAllChildren, []);

    return {
      hasStickyProductImageGallery: Boolean(
        layoutComponentsChildren.find(
          ({ name, properties }) => name === 'productImageGallery' && properties?.content?.sticky
        )
      ),
      hasProductAccordions: Boolean(
        layoutComponentsChildren.find(({ name }) => name === 'productAccordions')
      ),
      ...props,
    };
  }, [layoutComponents, props]);

  return <LayoutDesignContext.Provider value={value}>{children}</LayoutDesignContext.Provider>;
};
