const tiers = {
  1: ['de_de', 'en_de', 'en_us', 'en_nl', 'nl_nl'],
  2: ['ja_jp', 'en_jp', 'en_za', 'en_gb', 'en_au', 'fr_fr', 'en_fr'],
  3: [
    'en_sg',
    'en_gr',
    'en_bg',
    'en_dk',
    'en_se',
    'de_ch',
    'en_ch',
    'fr_ch',
    'de_at',
    'en_at',
    'en_it',
    'fr_be',
    'en_be',
    'nl_be',
    'en_ca',
    'fr_ca',
    'en_ie',
    'es_es',
    'en_es',
  ],
  4: [
    'en_cz',
    'en_ph',
    'en_th',
    'en_sa',
    'en_cy',
    'en_hr',
    'en_lt',
    'en_lu',
    'en_lv',
    'en_ee',
    'en_il',
    'en_fi',
    'en_ro',
    'en_hu',
    'en_sk',
    'en_si',
    'en_no',
    'en_pl',
    'en_ru',
    'en_tw',
    'en_nz',
    'en_pt',
    'en_hk',
    'en_ae',
    'en_mo',
    'en_kr',
    'ko_kr',
  ],
};

const locales = ['default', ...tiers[1], ...tiers[2], ...tiers[3], ...tiers[4]];

module.exports = {
  i18n: {
    locales,
    defaultLocale: 'default',
    // localeDetection: true,
  },
  fallbackLng: {
    default: ['default'],
  },
  react: {
    useSuspense: false,
  },
  tiers,
};
