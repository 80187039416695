import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';

type SmartAppBannerContextType = {
  smartBannerLoaded: boolean;
  smartBannerHeight: number;
};

const SmartAppBannerContext = createContext<SmartAppBannerContextType>({
  smartBannerLoaded: true,
  smartBannerHeight: 0,
});

export const useSmartBannerContext = () => useContext(SmartAppBannerContext);

export const SmartBannerProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: SmartAppBannerContextType;
}) => {
  const memoizedValue = useMemo(() => ({ ...value }), [value]);

  return (
    <SmartAppBannerContext.Provider value={memoizedValue}>
      {children}
    </SmartAppBannerContext.Provider>
  );
};
