import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const PinIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg viewBox="0 0 1024 1024" $hoverColor={hoverColor}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={color}>
        <path d="M611 473a323 323 0 1 0-572 4l282 545 290-549Zm-287-42a108 108 0 1 0 0-215 108 108 0 0 0 0 215Z" />
      </g>
    </g>
  </Svg>
);

export default PinIcon;
