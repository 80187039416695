import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

const ShoppingBagIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg viewBox="0 0 1024 1024" $hoverColor={hoverColor}>
    <g fill={color}>
      <path d="M875 204H686v-93C686 57 647 0 575 0H451c-72 0-111 57-111 111v93H151c-41 0-74 33-74 74v672c0 41 33 74 74 74h724c41 0 74-33 74-74V278c0-41-33-74-74-74zm-448-93c0-5 1-25 24-25h124c23 0 24 20 24 25v93H427v-93zm436 827H163V290h177v78a43 43 0 0 0 87 1v-79h172v78a43 43 0 0 0 87 1v-79h177v648z" />
    </g>
  </Svg>
);

export default ShoppingBagIcon;
