import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

export const NewWindowIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg
    version="1.1"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1024 1024"
    width="100%"
    height="100%"
    overflow="inherit"
    xmlSpace="preserve"
    $hoverColor={hoverColor}
  >
    <g fill={color}>
      <path d="M951.43.02a38.91 38.91 0 0 0-4.664.433H700.94a39.738 39.738 0 0 0-16.21 3.207c-5.149 2.195-9.836 5.461-13.79 9.602-3.957 4.14-7.093 9.074-9.234 14.515a46.893 46.893 0 0 0-3.246 17.18 46.888 46.888 0 0 0 3.246 17.176c2.14 5.441 5.277 10.375 9.234 14.515 3.954 4.141 8.641 7.407 13.79 9.606a39.734 39.734 0 0 0 16.21 3.203h150.196L420.023 547.516c-4.02 4.101-7.23 9.011-9.441 14.445a46.864 46.864 0 0 0-3.434 17.207 46.913 46.913 0 0 0 3.106 17.273c2.105 5.485 5.219 10.465 9.16 14.649 3.941 4.187 8.629 7.496 13.785 9.734a39.771 39.771 0 0 0 16.262 3.301 39.791 39.791 0 0 0 16.191-3.652c5.118-2.348 9.739-5.758 13.598-10.028l431.117-458.058v159.582a47.041 47.041 0 0 0 3.012 17.222c2.07 5.473 5.144 10.454 9.039 14.653 3.898 4.199 8.543 7.535 13.664 9.812a39.796 39.796 0 0 0 16.164 3.446 39.8 39.8 0 0 0 16.168-3.446c5.121-2.277 9.766-5.613 13.664-9.812 3.895-4.2 6.969-9.18 9.04-14.653a47.048 47.048 0 0 0 3.019-17.222V50.605a47.211 47.211 0 0 0-1.442-19.117c-1.78-6.183-4.804-11.883-8.87-16.699-4.067-4.812-9.075-8.629-14.665-11.18A39.75 39.75 0 0 0 951.43.02zM114.563 178.46c-45.782 0-83.77 40.36-83.77 89.005v667.531c0 48.645 37.988 89.004 83.77 89.004h628.265c45.777 0 83.77-40.36 83.77-89.004V375.242l-83.77 89.004v470.75H114.563V267.465H557.62l83.766-89.004zm0 0" />
    </g>
  </Svg>
);

export default NewWindowIcon;
