import styled from 'styled-components';

interface StyledIconProps {
  $color?: string;
  $hoverColor?: string;
}

export const Svg = styled.svg<StyledIconProps>`
  width: 100%;
  height: 100%;

  &:hover,
  &:focus {
    g {
      fill: ${({ $hoverColor }) => $hoverColor};
    }
  }
`;
