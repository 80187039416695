import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const CloseIcon = ({ color = colors.BLACK, hoverColor, onClick }: IconProps): ReactElement => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    $hoverColor={hoverColor}
    onClick={onClick}
  >
    <g fill={color}>
      <polygon
        points="1024,83.102 940.801,0 512,428.902 83.203,0 0,83.102 428.801,512 0,940.902 83.203,1024 512,595.102 
		940.801,1024 1024,940.902 595.203,512 	"
      />
    </g>
  </Svg>
);

export default CloseIcon;
