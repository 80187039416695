import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

const LoginIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg viewBox="0 0 1024 1024" $hoverColor={hoverColor}>
    <path
      fill={color}
      d="M512 0a251 251 0 0 0-256 257c0 144 111 255 256 255 146 0 256-111 256-255C768 111 658 0 512 0m416 751v273H96V738c0-75 73-162 163-162h506c90 0 163 87 163 162v13z"
    />
  </Svg>
);

export default LoginIcon;
