import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const ArrowRightLineIcon = ({
  color = colors.BLACK,
  hoverColor,
  className,
}: IconProps): ReactElement => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    enableBackground="new 0 0 1024 1024"
    $hoverColor={hoverColor}
    className={className}
  >
    <g fill={color}>
      <path d="M 320.80765,3.8780376 221.17773,104.25654 622.53881,508.63858 221.17952,913.02063 l 99.62813,100.37667 425.51643,-428.71871 75.4728,-76.04001 -23.79796,-23.97754 -51.67307,-52.06246 z" />
    </g>
  </Svg>
);

export default ArrowRightLineIcon;
