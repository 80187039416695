import type { ReactElement, ButtonHTMLAttributes, MouseEventHandler } from 'react';
import { forwardRef } from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import { colors } from '../styles';
import PinIcon from '../icons/PinIcon';
import LoginIcon from '../icons/LoginIcon';
import HeartFullIcon from '../icons/HeartFullIcon';
import HeartEmptyIcon from '../icons/HeartEmptyIcon';
import ShoppingBagIcon from '../icons/ShoppingBagIcon';
import ArrowRightLineIcon from '../icons/ArrowRightLineIcon';
import type { IconProps } from '../icons/index';
import NewWindowIcon from '../icons/NewWindowIcon';
import BellIcon from '../icons/BellIcon';
import CloseIcon from '../icons/CloseIcon';

export type Icon =
  | 'close'
  | 'pin'
  | 'login'
  | 'heartFull'
  | 'heartEmpty'
  | 'shoppingBag'
  | 'arrowRight'
  | 'newWindow'
  | 'bell';

const IconMap: Record<Icon, (props: IconProps) => ReactElement> = {
  close: CloseIcon,
  pin: PinIcon,
  login: LoginIcon,
  heartFull: HeartFullIcon,
  heartEmpty: HeartEmptyIcon,
  shoppingBag: ShoppingBagIcon,
  arrowRight: ArrowRightLineIcon,
  newWindow: NewWindowIcon,
  bell: BellIcon,
};

export interface ButtonProps extends ButtonHTMLAttributes<HTMLElement> {
  label?: string;
  textTransform?: 'lowercase' | 'capitalize' | 'uppercase';
  ordinal?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'transparent';
  className?: string;
  disabled?: boolean;
  iconColour?: string;
  testId?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  href?: string;
  icon?: Icon | null;
  ariaLabel?: string;
  hardReload?: boolean;
}

interface StyledButtonProps {
  $textTransform?: 'lowercase' | 'capitalize' | 'uppercase';
  $ordinal?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'transparent';
}

const styles = {
  button: css`
    display: inline-block;
    font-family: 'Gotham HCo SSm', Arial, Helvetica, sans-serif;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 36px;
    padding: 0 20px;
    border: none;
    outline: 0;
    cursor: pointer;
    box-sizing: border-box;
    min-width: 133px;
  `,

  primary: css`
    background-color: ${colors.NERO_GREY};
    border: 2px solid ${colors.NERO_GREY};
    color: ${colors.WHITE};

    g {
      fill: ${colors.WHITE};
    }

    &:hover,
    &:focus {
      color: ${colors.NERO_GREY};
      background-color: ${colors.WHITE};

      g {
        fill: ${colors.NERO_GREY};
      }
    }
  `,

  secondary: css`
    font-size: 14px;
    background-color: transparent;
    border: 2px solid ${colors.BORDER_GREY};
    color: ${colors.NERO_GREY};

    @media (hover: hover) {
      &:hover,
      &:focus {
        border: 2px solid ${colors.NERO_GREY};
      }
    }

    g {
      fill: ${colors.NERO_GREY};
    }
  `,

  tertiary: css`
    border: 2px solid ${colors.NERO_GREY};
    background-color: ${colors.WHITE};
    color: ${colors.NERO_GREY};

    g {
      fill: ${colors.NERO_GREY};
    }

    @media (hover: hover) {
      &:hover,
      &:focus {
        background-color: ${colors.NERO_GREY};
        color: ${colors.WHITE};

        g {
          fill: ${colors.WHITE};
        }
      }
    }

    &:active {
      background-color: ${colors.ACCESSIBILITY_GREY};
      color: ${colors.WHITE};
      border-color: ${colors.ACCESSIBILITY_GREY};
    }
  `,

  success: css`
    background-color: ${colors.SUCCESS_BLUE};
    border: 2px solid ${colors.SUCCESS_BLUE};
    color: ${colors.WHITE};

    g {
      fill: ${colors.WHITE};
    }

    @media (hover: hover) {
      &:hover,
      &:focus {
        color: ${colors.SUCCESS_BLUE};
        background-color: transparent;

        g {
          fill: ${colors.SUCCESS_BLUE};
        }
      }
    }

    &:active {
      background-color: transparent;
      color: ${colors.SUCCESS_BLUE};
    }
  `,

  transparent: css`
    background-color: transparent;
    border: 2px solid ${colors.WHITE};
    color: ${colors.WHITE};

    g {
      fill: ${colors.WHITE};
    }

    @media (hover: hover) {
      &:hover,
      &:focus {
        color: ${colors.NERO_GREY};
        background-color: ${colors.WHITE};

        g {
          fill: ${colors.NERO_GREY};
        }
      }
    }

    &:active {
      border: 2px solid ${colors.ACCESSIBILITY_GREY};
      fill: ${colors.WHITE};
      background-color: ${colors.ACCESSIBILITY_GREY};

      g {
        fill: ${colors.WHITE};
      }
    }
  `,

  disabled: css`
    cursor: default;
    color: ${colors.ACCESSIBILITY_GREY};
    background: ${colors.BORDER_GREY};
    border-color: ${colors.BORDER_GREY};
    pointer-events: none;
  `,
};

const S = {
  Button: styled.button<StyledButtonProps>`
    ${styles.button}
    text-transform: ${({ $textTransform }) => $textTransform};

    &.button--primary {
      ${styles.primary}
    }

    &.button--secondary {
      ${styles.secondary}
    }

    &.button--tertiary {
      ${styles.tertiary}
    }

    &.button--success {
      ${styles.success}
    }

    &.button--transparent {
      ${styles.transparent}
    }

    &.button--disabled {
      ${styles.disabled}
    }
  `,
  Anchor: styled.a<StyledButtonProps>`
    ${styles.button}
    text-transform: ${({ $textTransform }) => $textTransform};
    text-decoration: none;

    &.button--primary {
      ${styles.primary}
    }

    &.button--secondary {
      ${styles.secondary}
    }

    &.button--tertiary {
      ${styles.tertiary}
    }

    &.button--success {
      ${styles.success}
    }

    &.button--transparent {
      ${styles.transparent}
    }

    &.button--disabled {
      ${styles.disabled}
    }
  `,
  IconWrapper: styled.span<{ $hasLabel: boolean }>`
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    line-height: 16px;
    ${({ $hasLabel }) => ($hasLabel ? 'margin-right: 8px;' : '')}
  `,
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      label,
      textTransform = 'lowercase',
      type = 'button',
      ordinal = 'primary',
      className = '',
      disabled = false,
      iconColour,
      href,
      icon,
      testId = undefined,
      onClick,
      ariaLabel,
      hardReload = false,
    }: ButtonProps,
    ref
  ) => {
    const IconComponent = icon ? IconMap[icon] : null;
    const hasLabel = !!label;
    const buttonClassName = disabled ? 'button--disabled' : `button--${ordinal}`;

    if (href) {
      const anchor = (
        <S.Anchor
          href={href}
          $textTransform={textTransform}
          $ordinal={ordinal}
          className={`${buttonClassName} ${className}`}
          data-testid={testId}
          onClick={onClick}
          aria-label={ariaLabel}
        >
          {IconComponent && (
            <S.IconWrapper $hasLabel={hasLabel}>
              <IconComponent color={disabled ? colors.ACCESSIBILITY_GREY : iconColour} />
            </S.IconWrapper>
          )}
          {label}
        </S.Anchor>
      );

      return hardReload ? (
        anchor
      ) : (
        <Link href={href} passHref legacyBehavior>
          {anchor}
        </Link>
      );
    }

    return (
      <S.Button
        ref={ref}
        type={type}
        $textTransform={textTransform}
        $ordinal={ordinal}
        disabled={disabled}
        className={`${buttonClassName} ${className}`}
        data-testid={testId}
        onClick={onClick}
        aria-label={ariaLabel}
      >
        {IconComponent && (
          <S.IconWrapper $hasLabel={hasLabel}>
            <IconComponent color={disabled ? colors.ACCESSIBILITY_GREY : iconColour} />
          </S.IconWrapper>
        )}
        {label}
      </S.Button>
    );
  }
);

Button.displayName = 'Button';
